<template>
    <a-checkbox type="checkbox" class="app-checkbox" :value="value" v-bind="parsedAttrs" v-on="parsedListeners" :checked="value" @input="onSelect"><slot/></a-checkbox>
</template>
<script>
export default {
    name: 'AppCheckbox',
    props: {
        value: {
            type: [String, Number, Boolean],
            default: ''
        }
    },
    computed: {
        parsedAttrs() {
            const attrs = {...this.$attrs};
            delete attrs.value;
            return attrs;
        },
        parsedListeners() {
            const listeners = {...this.$listeners};
            delete listeners.input;
            return listeners;
        },
    },
    methods: {
        onSelect(ev) {
            this.$emit('input', ev);
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/scss/variables';
.ant-checkbox + span {
    padding-left: 12px;
}
.ant-checkbox-checked + span {
    color: var(--color-primary);
}
.app-checkbox {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    font-family: $font-secondary;
}
</style>
