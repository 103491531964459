<template>
    <div class="app-tree-select-item" :style="{'--depth': depth}">
        <div v-if="hasChildren" class="app-tree-select-item__field" :class="{'app-tree-select-item__field--opened': visibleChildren}" @click="visible = !visible">
            <AppIcon class="app-tree-select-item__icon" icon="chevron-right"/>
            <div class="app-tree-select-item__title">{{ item[itemKey] }}</div>
        </div>
        <div v-else class="app-tree-select-item__field app-tree-select-item__field--checkbox">
            <AppCheckbox class="app-tree-select-item__checkbox" :value="isChecked" @input="onInput">
                {{ item[itemKey] }}
            </AppCheckbox>
            <div v-if="!hideItemAction" class="app-tree-select-item__more" @click="onMoreClick">
                <AppIcon icon="alert-circle"/>
            </div>
        </div>

        <transition :css="false" @before-enter="onBeforeEnter" @enter="onEnter" @after-enter="onAfterEnter" @before-leave="onBeforeLeave" @leave="onLeave" @after-leave="onAfterLeave">
            <div v-if="visibleChildren" class="app-tree-select-item__children" :style="{height}">
                <TreeOption v-for="subitem in item.children" :key="subitem[itemValue]" :item-value="itemValue" :item-key="itemKey" :hide-item-action="hideItemAction" :opened-items="openedItems" :model="model" :item="subitem" :depth="depth + 1" @input="onChildInput" @more-click="onChildMoreClick"/>
            </div>
        </transition>
    </div>
</template>
<script>
import AppIcon from "@/components/partials/AppIcon.vue";
import AppCheckbox from "@/components/partials/AppCheckbox.vue";

export default {
    name: 'TreeOption',
    components: {
        AppIcon,
        AppCheckbox,
    },
    props: {
        item: Object,
        depth: Number,
        hideItemAction: Boolean,
        itemKey: {
            type: String,
            default: 'title'
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        openedItems: {
            type: Array,
            default: () => ([])
        },
        model: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            visible: false,
            height: null,
        }
    },
    computed: {
        visibleChildren() {
            return this.hasChildren && this.visible || this.openedItems.length && this.openedItems.includes(this.item[this.itemValue]);
        },
        hasChildren() {
            return this.item.children && this.item.children.length;
        },
        isChecked() {
            return this.model.includes(this.item[this.itemValue]);
        }
    },
    methods: {
        onBeforeEnter() {
            this.height = '0px';
        },
        onEnter(el, done) {
            this.$nextTick(() => {
                this.height = el.scrollHeight + 'px';
            })
            setTimeout(() => {
                done();
            }, 250);
        },
        onAfterEnter() {
            this.height = null;
        },
        onBeforeLeave(el) {
            el.style.height = el.scrollHeight + 'px';
        },
        onLeave(el, done) {
            setTimeout(() => {
                el.style.height = '0px';
            }, 10);
            setTimeout(() => {
                done();
            }, 250);
        },
        onAfterLeave(el) {
            el.style.height = '0px';
        },

        onChildMoreClick(e) {
            this.$emit('more-click', e);
        },
        onMoreClick() {
            this.$emit('more-click', this.item[this.itemValue]);
        },
        onChildInput(e) {
            this.$emit('input', e);
        },
        onInput() {
            this.$emit('input', this.item[this.itemValue]);
        }
    }
}
</script>
