<template>
    <div class="app-tree-select" :class="{'app-tree-select--theme-filled': !onlyPlaceholder && (visible || selectedItems.length), 'app-tree-select--menu-reversed': isMenuReversed, 'app-tree-select--error': error, 'app-tree-select--disabled': disabled}" v-clickaway="close">
        <div class="app-tree-select__field" :class="{'app-tree-select--opened': visible}" ref="field" @click="open">
            <LoadingIcon v-if="loading" class="app-tree-select__loading"/>
            <template v-else>
                <transition-group v-if="!onlyPlaceholder && selectedItems.length" class="app-tree-select__tags-wrap" tag="div" name="tree-tag">
                    <div v-for="tag in selectedItems" :key="tag[itemValue]" class="app-tree-select__tag tree-tag" :class="{'tree-tag--disabled': disabled}">
                        <div class="tree-tag__text">
                            {{ tag[itemKey] }}
                        </div>
                        <div class="tree-tag__icon spacer-button" @click.stop="onMoreClick(tag[itemValue])">
                            <AppIcon icon="alert-circle"/>
                        </div>
                        <div v-if="!disabled" class="tree-tag__icon tree-tag__icon--danger spacer-button" @click.stop="onInput(tag[itemValue])">
                            <AppIcon icon="close-small"/>
                        </div>
                    </div>
                </transition-group>
                <div v-else-if="placeholder" class="app-tree-select__placeholder">{{ placeholder }}</div>
                <input v-model="search" v-if="visibleSearch" :disabled="disabled" class="app-tree-select__search" type="text" ref="search" @input="onSearch">
                <AppIcon class="app-tree-select__icon" icon="chevron-down"/>
            </template>
        </div>
        <transition :name="'dialer' + (isMenuReversed ? '-reversed' : '')">
            <div v-if="visible" class="app-tree-select__menu" @click="detectHeight">
                <TreeOption
                    v-for="item in options"
                    :hide-item-action="hideItemAction"
                    :key="item[itemValue]"
                    :item-value="itemValue"
                    :itemKey="itemKey"
                    :disabled="disabled"
                    :model="value"
                    :opened-items="openedItems"
                    :item="item"
                    :depth="1"
                    @input="onInput"
                    @more-click="onMoreClick"
                />
            </div>
        </transition>
    </div>
</template>
<script>
import AppIcon from "./AppIcon";
import clickaway from "../../directives/clickaway";
import LoadingIcon from "./LoadingIcon";
import TreeOption from "@/components/shared/old/tree-option/tree-option.component.vue";

export default {
    name: 'AppTreeSelect',
    components: {
        LoadingIcon,
        AppIcon,
        TreeOption
    },
    directives: {clickaway},
    props: {
        options: Array,
        flattenItems: Array,
        error: Boolean,
        disabled: Boolean,
        loading: Boolean,
        hideItemAction: Boolean,
        onlyPlaceholder: Boolean,
        itemKey: {
            type: String,
            default: 'title'
        },
        itemValue: {
            type: String,
            default: 'id'
        },
        placeholder: {
            type: String,
            default: ''
        },
        visibleSearch: {
            type: Boolean,
            default: true
        },
        value: {
            type: Array,
            default: () => ([])
        }
    },
    data() {
        return {
            visible: false,
            search: '',
            openedItems: [],
            isMenuReversed: false
        }
    },
    computed: {
        selectedItems() {
            if(!this.options.length) return [];
            const result = []
            function filterItems(items, key, value) {
                for(let i = 0; i < items.length; i++) {
                    if(items[i].children?.length) filterItems(items[i].children, key, value)
                    if(items[i][key] === value && result.indexOf(items[i]) === -1) result.push(items[i])
                }
            }

            for(let i = 0; i < this.value.length; i++) {
                filterItems(this.flattenItems, this.itemValue, this.value[i])
            }


            return result.filter(item => item)
        },
    },
    mounted() {
        window.addEventListener('keydown', this.onKeyboard);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeyboard);
    },
    methods: {
        detectHeight() {
            const fieldCoords = this.$refs.field.getBoundingClientRect();
            const height = fieldCoords.top + fieldCoords.height;
            this.isMenuReversed = height + 360 > window.innerHeight - 20;
        },
        onSearch(e) {
            const search = e.target.value;

            if(!search) {
                this.openedItems = [];
                return;
            }

            let collect = [];

            const findParents = (item, arr) => {
                if(item.parent_id === 0) return;

                const next = this.flattenItems.find(option => option[this.itemValue] === item.parent_id);
                arr.push(next);

                findParents(next, arr);
            }

            this.flattenItems.forEach(item => {
                if(item[this.itemKey].toLowerCase().includes(search)) {
                    collect.push(item[this.itemValue]);
                    let parents = [];
                    findParents(item, parents);
                    collect = collect.concat(parents.filter(parent => !collect.includes(parent[this.itemValue])).map(parent => parent[this.itemValue]));
                }
            })

            this.openedItems = collect;
        },
        onKeyboard({code}) {
            if(!this.visible) {
                return;
            }

            if(code === 'Escape') {
                this.close();
            }
        },
        open() {
            if(!this.options || this.disabled) return;

            this.detectHeight();
            this.visible = true;
            this.$refs.search?.focus();
        },
        close() {
            this.visible = false;
            this.search = '';
        },
        onInput(id) {
            if(this.disabled) return;

            let model = [...this.value];

            if(model.includes(id)) {
                model.splice(model.findIndex(v => v === id), 1);
            } else {
                model.push(id);
            }

            this.$refs.field?.focus();

            this.$emit('input', model);
            this.$emit('change', id);
        },
        onMoreClick(id) {
            this.$emit('more-click', id);
            this.close();
        },
    }
}
</script>
<style lang="scss">
@import '../../assets/scss/variables';
.app-tree-select-item {
    $self: &;
    &__children {
        overflow: hidden;
        transition: height .25s;
    }
    &__field {
        cursor: pointer;
        user-select: none;
        height: 52px;
        display: flex;
        align-items: center;
        padding: 16px;
        padding-left: calc(var(--depth) * 16px);
        position: relative;
        &--opened {
            #{$self}__icon {
                transform: rotate(90deg);
            }
        }
        &--checkbox {
            padding: 0 !important;
            display: block;
        }
        &:hover {
            background-color: var(--color-white-bluesh);
            color: var(--color-primary);
            #{$self}__icon {
                color: var(--color-primary);
            }
        }
    }
    &__icon {
        transition: transform .25s;
        margin-right: 12px;
        width: 18px;
        height: 18px;
        color: var(--color-gray-lighten);
        flex-shrink: 0;
    }
    &__title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        font-family: $font-secondary;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    &__checkbox {
        width: 100%;
        height: 100%;
        padding: 16px;
        padding-left: calc(var(--depth) * 16px);
        display: flex;
        align-items: center;

        .ant-checkbox + span {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100% - 60px);
            overflow: hidden;
        }
    }
    &__more {
        cursor: pointer;
        user-select: none;
        padding: 10px;
        position: absolute;
        top: 5px;
        right: 24px;
        width: 40px;
        height: 40px;
        color: var(--color-white-darken);
        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
        &:hover {
            color: var(--color-primary);
        }
    }
    & + & {
        #{$self}__field {
            border-top: 1px solid var(--color-border);
            padding-top: 15px;
        }
    }
}
.tree-tag {
    $self: &;
    transition: transform .25s;
    cursor: pointer;
    user-select: none;

    display: flex;
    align-items: center;
    padding: 10px;
    background: #fff;
    border-radius: 2px;

    &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;

        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: var(--color-dark);
    }

    &__icon {
        transition: color .25s;
        width: 16px;
        height: 16px;
        margin-left: 8px;
        color: #949494;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
        &--danger {
            color: #FF4921;
        }
        &:hover {
            color: var(--color-black);
        }
    }
    &--disabled {
        #{$self}__icon {
            color: var(--color-primary);
        }
    }

    &-enter-active, &-leave-active, &-move {
        transition: opacity .25s, transform .25s;
    }
    &-enter, &-leave-to {
        opacity: 0;
    }
}
.app-tree-select {
    $self: &;
    position: relative;
    &__field {
        transition: border .25s, background-color .25s;
        cursor: text;
        user-select: none;
        display: flex;
        align-items: center;
        border: 1px solid var(--color-border);
        background-color: var(--color-white);

        border-radius: 4px;
        min-height: 48px;
    }
    &__tags-wrap {
        margin-left: -6px;
        margin-top: -6px;
        padding: 3px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &__tag {
        max-width: 100%;
        margin-left: 6px;
        margin-top: 6px;
    }
    &__search {
        background-color: transparent;
        border: 0;
        padding: 5px 41px 5px 15px;
        flex-grow: 1;
        flex-shrink: 0;
        min-width: 80px;
        width: 0;
        height: 46px;
        align-self: flex-start;
        font-family: $font-secondary;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        outline: none !important;
    }
    &__menu {
        z-index: 11;
        position: absolute;
        top: calc(100% - 1px);
        left: 0;
        width: 100%;
        background: var(--color-white);
        box-shadow: 0px 2px 20px var(--shadow-dialer);
        border-radius: 4px;
        max-height: 360px;
        overflow-y: scroll;
    }
    &__icon {
        pointer-events: none;
        position: absolute;
        right: 13px;
        top: 50%;
        margin-top: -8px;
        transition: transform .25s;
        flex-shrink: 0;
        z-index: 1;
        color: #949494;
        width: 16px;
        height: 16px;
        svg {
            width: 100%;
            height: 100%;
            display: block;
        }
    }
    &__loading {
        margin-right: 18px;
        margin-left: auto;
        width: 20px;
        height: 20px;
        color: var(--color-gray-lighten);
    }
    &__placeholder {
        margin-left: 17px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.005em;
        color: #949494;
        font-family: $font-secondary;
    }
    &--theme-filled {
        #{$self}__field {
            background: var(--color-white-bluesh);
            border: 1px solid var(--color-primary);
        }
    }
    &--opened {
        #{$self}__icon {
            transform: rotate(180deg);
        }
    }
    &--menu-reversed {
        #{$self}__menu {
            top: auto;
            bottom: calc(100% - 1px);
        }
    }
    &--error {
        #{$self}__field {
            border: 1px solid var(--color-danger);
            animation: error 0.5s 1 both;
        }
    }
    &--disabled {
        > * {
            cursor: default;
        }
        #{$self}__field {
            border: 1px solid var(--color-border);
            background: var(--color-border-lighten);
        }
        #{$self}__tags-wrap {
            max-width: 100%;
        }
        #{$self}__tag {
            color: var(--color-gray-lighten);
        }
        #{$self}__search,
        #{$self}__icon {
            display: none;
        }
    }
}
</style>
